/* src/components/Navbar/Navbar.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-toggler {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
}

.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.navbar-item {
  width: 100%;
  text-align: center;
}

.navbar-item a,
.navbar-item .auth-button {
  display: block;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background: none;
}

.auth-button {
  background-color: #007bff;
  color: white;
  /* border-radius: 4px; */
  /* margin: 0.5rem 0; */
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .navbar-toggler {
    display: block;
  }

  .navbar-menu {
    flex-direction: column;
    max-height: 100vh; /* Ensure space for all items */
    overflow-y: auto; /* Enable scrolling if content exceeds height */
  }

  .navbar-item a,
  .navbar-item {
    padding: 0.75rem 1rem; /* Ensure enough padding for mobile */
  }

  .navbar-item {
    padding: 0; /* Remove padding to align items */
    margin: 0; /* Remove margin to ensure items fit */
  }
}

@media (min-width: 769px) {
  .navbar-menu {
    position: static;
    flex-direction: row;
    background-color: transparent;
    box-shadow: none;
    height: auto !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: flex-end;
  }

  .navbar-item {
    width: auto;
    padding: 0.5rem;
    text-align: left;
  }

  .navbar-item:first-child {
    margin-left: 0;
  }

  .navbar-item a,
  .navbar-item .auth-button {
    padding: 0;
    color: #333;
    font-size: 1rem;
  }

}