.trading-bot-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.trading-bot-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.amount-input {
  margin: 20px 0;
}

.amount-input label {
  font-size: 16px;
  color: #333;
}

.amount-input input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 10px;
}

.trade-buttons {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.trade-buttons button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.trade-buttons button:hover {
  background-color: #0056b3;
}

.transaction-history {
  margin-top: 30px;
  text-align: left;
}

.transaction-history h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.transaction-history ul {
  list-style-type: none;
  padding: 0;
}

.transaction-history li {
  background-color: #e3f2fd;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 14px;
}