/* PhantomWalletConnect.css */

.wallet-container {
  padding: 20px;
  max-width: 100%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.wallet-address {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
  word-break: break-all; /* Ensures long addresses break nicely on small screens */
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #007bff;
  text-align: center;
}

h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.token-dropdown {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  transition: border-color 0.3s ease;
}

.token-dropdown:focus {
  border-color: #007bff;
  outline: none;
}

.token-details {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 14px;
}

.token-details p {
  margin: 5px 0;
  word-break: break-word; /* Ensure long addresses and names break nicely */
}

.connect-button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.connect-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

@media (max-width: 768px) {
  .wallet-container {
    padding: 15px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 14px;
  }

  .token-details {
    font-size: 13px;
  }

  .connect-button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .wallet-container {
    padding: 10px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 13px;
  }

  .wallet-address,
  .token-details p {
    font-size: 12px;
  }

  .connect-button {
    font-size: 14px;
    padding: 8px;
  }
}