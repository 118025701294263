.solana-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .wallet-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .wallet-button:hover {
    background-color: #45a049;
  }
  
  .token-selector {
    margin-top: 20px;
  }
  
  .token-selector label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .token-selector select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
  }
  
  @media (max-width: 480px) {
    .solana-container {
      padding: 15px;
    }
  
    .wallet-button {
      font-size: 14px;
      padding: 8px;
    }
  
    .token-selector select {
      font-size: 14px;
      padding: 8px;
    }
  }